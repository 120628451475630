<template>
  <div>
    <h4>
      {{ $t("time.buffer.title") }}
    </h4>
    <inputSelect
      :key="getDefaultDuration()"
      :settings="{
        value: getDefaultDuration(),
        default: getDefaultDuration(),
        values: getValues()
      }"
      :callback="setSave"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: false,
      values: this.getValues()
    };
  },
  computed: {},
  mounted() {
    this.data = this.getDefaultDuration();
  },
  methods: {
    getValues() {
      var val = {
        "0": this.$t("default.no"),
        "5": this.$t("time.buffer.option", { time: 5 }),
        "10": this.$t("time.buffer.option", { time: 10 }),
        "15": this.$t("time.buffer.option", { time: 15 }),
        "20": this.$t("time.buffer.option", { time: 20 }),
        "25": this.$t("time.buffer.option", { time: 25 }),
        "30": this.$t("time.buffer.option", { time: 30 }),
        "35": this.$t("time.buffer.option", { time: 35 }),
        "40": this.$t("time.buffer.option", { time: 40 }),
        "45": this.$t("time.buffer.option", { time: 45 }),
        "50": this.$t("time.buffer.option", { time: 50 }),
        "55": this.$t("time.buffer.option", { time: 55 }),
        "60": this.$t("time.buffer.option", { time: 60 }),
        "90": this.$t("time.buffer.option", { time: 90 }),
        "120": this.$t("time.buffer.option", { time: 120 })
      };
      return val;
    },
    setSave(ret) {
      this.$store.commit("tableau/setUpdate");
      this.data = ret;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (typeof data.include.buffer === "undefined") {
        data.include.buffer = {};
      }
      data.include.buffer[this.$store.state.business.weekday] = this.data;

      if (this.$store.state.tableau.view === "week") {
        Object.keys(data.include.buffer).forEach(
          function(k) {
            data.include.buffer[k] = this.data;
          }.bind(this)
        );
      }
      this.$store.commit("business/prefetch", data);
    },
    getDefaultDuration() {
      if (
        typeof this.$store.state.business.unsaved.include.buffer[
          this.$store.state.business.weekday
        ] !== "undefined"
      ) {
        return this.$store.state.business.unsaved.include.buffer[
          this.$store.state.business.weekday
        ];
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
