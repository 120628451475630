import { render, staticRenderFns } from "./buffer.vue?vue&type=template&id=46d93407&scoped=true&"
import script from "./buffer.vue?vue&type=script&lang=js&"
export * from "./buffer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d93407",
  null
  
)

export default component.exports